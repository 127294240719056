
export default {
  emits: ['input'],

  props: {
    color: {
      type: String,
      default: undefined,
    },
    headerColor: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      menu: false,
    }
  },

  computed: {
    time: {
      get() {
        return this.value || ''
      },
      set(val) {
        this.$emit('input', val)
      },
    },

    timeFormat() {
      return this.$dayjs().format('LT').toLowerCase().includes('a')
        ? 'ampm'
        : '24hr'
    },
  },
}
